import {graphql, useStaticQuery} from "gatsby"
import {ProjectEntity} from "../entity/project-entity";

type Props = {
  allPrismicProjects: {
    nodes: {
      uid: string
      url: string
      tags: string[]
      first_publication_date: string
      last_publication_date: string
      data: {
        name: {
          text: string
        }
        description: {
          text: string
        }
        app_icon: {
          url: string
        }
        google_play: {
          url: string
        }
        app_store: {
          url: string
        }
        cover: {
          url: string
        }
        video: {
          url: string
        }
      }
    }[]
  }
}

const useProjects: () => ProjectEntity[] = () => {
  const data = useStaticQuery<Props>(graphql`
    query AllProjects {
      allPrismicProjects(sort:{fields: first_publication_date, order:DESC}) {
        nodes {
          uid
          url
          tags
          first_publication_date
          last_publication_date
          data {
            name {
              text
            }
            description {
              text
            }
            app_icon {
              url
            }
            google_play {
              url
            }
            app_store {
              url
            }
            cover {
              url
              fluid {
                src
              }
            }
            video {
              url
            }
          }
        }
      }
    }
  `)
  return data.allPrismicProjects.nodes.map(value => {
    const {uid, data} = value;
    return {
      id: uid,
      title: data.name.text,
      description: data.description.text,
      appIconUrl: data.app_icon.url,
      playStoreUrl: data.google_play.url,
      appStoreUrl: data.app_store.url,
      coverImageUrl: data.cover.url,
      videoUrl: data.video.url,
    } as ProjectEntity
  });
}

export default useProjects
