import React, {ReactNode} from "react"
import {classNames} from "../utils";

type Props = {
  title: string
  slogan: string
  intro: ReactNode
  className?: string
}

const Heros: React.FC<Props> = ({title, slogan, intro, className}) => {
  return (
    <section className={classNames("px-2 pb-8", className)}>
      <div className="container items-center max-w-6xl px-8 mx-auto ">
        <div className="flex flex-wrap items-center sm:-mx-3">
          <div className="w-full">
            <div className="w-full pb-6 space-y-6">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl text-center">
                <span className="block items-center">{title}</span>
                <span className="block text-indigo-600">{slogan}</span>
              </h1>
              <div className="mx-auto text-base text-gray-500 sm:max-w-3xl ">
                {intro}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-2">
          <h1 className="text-sm font-bold tracking-tight text-white">
            <span className="bg-black px-8 py-4 text-white rounded-3xl">OUR LATEST GAMES</span>
          </h1>
        </div>
      </div>
    </section>
  );
}
export default Heros
