import * as React from "react"
import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import {createNavItems} from "../utils/nav-items-util";
import ProjectPost from "../components/ProjectPost";
import Heros from "../components/Heros";
import useProjects from "../hooks/use-projects";
import {graphql} from "gatsby";

type Props = {
  data: {
    prismicHomepage: {
      data: {
        title: {
          text: string
        }
        slogan: {
          text: string
        }
        intro: {
          html: string
        }
      }
    }
  }
}

const IndexPage: React.FC<Props> = ({data}) => {
  const projects = useProjects()
  const projectPosts = projects.map(value => {
    return (<ProjectPost key={value.id} project={value} className="mt-8"/>);
  })
  const homepage = data.prismicHomepage.data;
  const intro = <div className="prose mx-auto" dangerouslySetInnerHTML={{__html: homepage.intro.html}}/>;
  return (
    <Layout navItems={createNavItems({current: "/"})}>
      <PageWrapper className={"mt-16 mb-16"}>
        <Heros
          title={homepage.title.text}
          slogan={homepage.slogan.text}
          intro={intro}
        />
        {projectPosts}
      </PageWrapper>
    </Layout>
  )
};

export default IndexPage

export const query = graphql`
  query HomePage {
    prismicHomepage {
      data {
        title {
          text
        }
        slogan {
          text
        }
        intro {
          html
        }
      }
    }
  }
`;
