import React from "react"
import {classNames} from "../utils";

type Props = {
  title: string
  videoSrcUrl: string
  className?: string
}

const Video: React.FC<Props> = ({title, videoSrcUrl, className = ""}) => (
  <div className={classNames("video", className)}>
    <iframe
      loading="lazy"
      src={videoSrcUrl}
      title={title}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      // @ts-ignore
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      className="w-full h-96"
    />
  </div>
)
export default Video
