import React from "react";
import {ProjectEntity} from "../entity/project-entity";
import Video from "./Video";
import {classNames} from "../utils";

type Props = {
  project: ProjectEntity
  className?: string
}

const ProjectPost: React.FC<Props> = ({project, className = ""}) => {
  const {
    id,
    title,
    description,
    playStoreUrl,
    appStoreUrl,
    coverImageUrl,
    videoUrl
  } = project

  return (
    <div className={classNames("w-full flex flex-wrap bg-white", className)}>
      <div className="w-full md:w-2/3 bg-white overflow-hidden">
        {(!videoUrl && coverImageUrl) && (
          <img src={coverImageUrl} className="object-contain h-auto max-h-96 w-full" alt={title}/>)}
        {videoUrl && (<Video
          className="object-cover h-auto max-h-96 w-full"
          videoSrcUrl={videoUrl}
          title={title}/>)}
      </div>
      <div className="w-full md:w-1/3 flex flex-col bg-white overflow-hidden pl-4 pt-2">
        <div className="font-medium text-2xl">
          <a href={`/projects/${id}`}>{title}</a>
        </div>
        <div className="text-xl">{description}</div>
        <div className="mt-2 mb-2 -ml-2 flex">
          {playStoreUrl && (<a href={playStoreUrl} target="_blank" rel="noopener">
            <img className="h-16 object-contain" src="/images/google-play-badge.png" width="165" height="64"
                 alt="Download from Google Play"/>
          </a>)}
          {appStoreUrl && (<a href={appStoreUrl} target="_blank" rel="noopener">
            <img className="h-16 object-contain" src="/images/app-store-badge.png"  width="130" height="64"
                 alt="Download from App Store"/>
          </a>)}
        </div>
      </div>
    </div>
  );
}

export default ProjectPost;
